import React, { useEffect, useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Components
import BgSection from '../components/BgSection'

export default function Legal() {
    const { type, slug } = useParams();

    const validTypes = ['privacy', 'terms-of-use'];
    const [content, setContent] = useState(null);
    const [notFound, setNotFound] = useState(false);

    if (!validTypes.includes(type)) {
        return <Navigate to='/' replace />;
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await import(`../data/legal/${type}/`);
                const foundItem = data.default.find((item) => item.slug === slug);
                if (foundItem) {
                    setContent(foundItem);
                } else {
                    setNotFound(true); // Trigger navigation if not found
                }
            } catch (err) {
                console.error(err);
                setNotFound(true); // Trigger navigation on error
            }
        };
        loadData();
    }, []);

    let title = ""
    if (type === 'privacy') title = "Privacy Statement"
    if (type === 'terms-of-use') title = "Terms of Use"

    if (notFound) {
        return <Navigate to='/' replace />;
    }

    return (
        <>
            {content &&
                <Helmet>
                    <title>{title} | {content.title}</title>
                    <meta name="twitter:title" content={`${title} | ${content.title}`} />
				    <meta property="og:title" content={`${title} | ${content.title}`} />
                </Helmet>
            }

            <BgSection>
                <div className='h-full flex flex-col min-h-[96vh] justify-between'>
                    <div className='m-12 flex flex-col gap-12'>
                        {content &&
                            <>
                                <h1 className='font-passionOne uppercase text-8vw'>{content.title}</h1>

                                <div className='flex flex-col gap-10'>
                                    <h2 className='uppercase text-xl'>{title}</h2>
                                    {content.text.map((item, index) => (
                                        <div className='flex flex-col gap-4'>
                                            {item.title !== null &&
                                                <h3 key={index} className='text-xl uppercase'>{item.title}</h3>
                                            }
                                            <div>
                                                {item.text.map((text, index) => (
                                                    <p key={index} className='text-base leading-8 min-h-[27px]'>{text}</p>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                    </div>

                    <p className='text-center mt-12'>Copyrights by Mees Akveld, all rights reserved</p>
                </div>
            </BgSection>

        </>
    )
}
