import React, { useRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

// GSAP
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

// Components
import BgSection from '../components/BgSection'
import Intro from '../components/Intro'
import Title from '../components/Title'
import Card from '../components/Card'
import WorkTogether from '../components/WorkTogether'

// Assets
import person from '../assets/images/person.svg'
import { drone, dumplings, hiking, howIMetYourMother, mintTea, photography, stockholm, studying, sweden, theOffice, traveling } from '../assets/images/about-pictures';
import Button from '../components/Button';


export default function About() {

    // ———— Days I've been learning Swedish on Duolingo ————
    const [totalDaysLearningSwedish, setTotalDaysLearningSwedish] = useState('...')
    useEffect(() => {
        const startDate = new Date('2024-07-07')
        const currentDate = new Date()
        const timeDifference = currentDate.getTime() - startDate.getTime()
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
        setTotalDaysLearningSwedish(daysDifference)
    }, [])

    // ———— Important to me ————
    const importantToMe = [
        { title: 'Health', caption: "My physical and emotional health are my top priorities. Time management, exercise, and healthy eating are essential to my well-being." },
        { title: 'Family', caption: "My family and my loved ones are very important to me. I know that I wouldn't be who I am today without them." },
        { title: 'Intellectual', caption: `I invest time in learning every day and staying up-to-date with what is going on in the world. Currently, I have been learning Swedish for the past ${totalDaysLearningSwedish} days.` },
        { title: 'School', caption: "I prioritize my education by dedicating time each day to practicing and applying what I've learned. I am committed to achieving academic success and expanding my knowledge." },
        { title: 'Professional', caption: "I am passionate about my career and strive to be the best version of myself. I am dedicated to my work and always looking for ways to improve and grow." },
    ]

    // -> GSAP Important to me animation
    const importToMeRef = useRef()
    useGSAP(() => {
        // Repeating small grow animation for each circle separately
        gsap.to('.gsap-circle1', { scale: 1.1, repeat: -1, duration: 2, yoyo: true, stagger: 0.2 })
        gsap.to('.gsap-circle2', { scale: 1.1, repeat: -1, duration: 2, yoyo: true, delay: 0.2, stagger: 0.2 })
        gsap.to('.gsap-circle3', { scale: 1.1, repeat: -1, duration: 2, yoyo: true, delay: 0.4, stagger: 0.2 })
        gsap.to('.gsap-circle4', { scale: 1.1, repeat: -1, duration: 2, yoyo: true, delay: 0.6, stagger: 0.2 })
        gsap.to('.gsap-circle5', { scale: 1.1, repeat: -1, duration: 2, yoyo: true, delay: 0.8, stagger: 0.2 })
    })

    // ———— Fun Things ————
    const funThings = [
        { title: 'Hiking', img: hiking },
        { title: 'Dumplings', img: dumplings },
        { title: 'Studying', img: studying },
        { title: 'The Office', img: theOffice },
        { title: 'Photography', img: photography },
        { title: 'Drone', img: drone },
        { title: 'Mint Tea', img: mintTea },
        { title: 'Sweden', img: sweden },
        { title: 'Stockholm', img: stockholm },
        { title: 'Traveling', img: traveling },
        { title: 'How I Met Your Mother', img: howIMetYourMother },
    ]

    return (
        <>
            <Helmet>
                <title>About | Mees Akveld</title>
                <meta name="twitter:title" content="About | Mees Akveld" />
				<meta property="og:title" content="About | Mees Akveld" />
            </Helmet>

            <div className='flex flex-col gap-0'>

                <BgSection className="pt-24 md:pt-24 flex flex-col gap-24 md:gap-40 z-10">

                    <Intro titlePrt1='Know more' titlePrt2='About Me' subtitle="Now it's time to:" />

                    <Title title='Who am I?' titlePrt1='Who' titlePrt2='am I?' titleH='h1' imgPath={person} />

                    <h2 className='hidden'>About me</h2>

                    <div className='flex flex-col gap-8'>

                        {/* Who am I */}
                        <Card title="Who am I?" description="I'm a 22-year-old programming student at Artevelde University of Applied Sciences in Ghent, Belgium. My passion lies in designing, creating, and developing digital projects. With my strategic mindset and strong organizational skills, I ensure that I deliver high-quality results.">
                            <div className='flex flex-col gap-4 sm:flex-row sm:gap-0 sm:justify-between mt-8 sm:mb-8'>
                                <h3 className='text-[4vw] font-[400] leading-[100%]' style={{ fontSize: 'clamp(1vw, 4vw, 2rem)' }}>I'm a Student</h3>
                                <div className='flex flex-col gap-12 sm:w-1/2'>
                                    <div className='flex flex-col gap-4'>
                                        <p>As a student at Artevelde University of Applied Sciences, I fulfill the role of chairman in the Program Student Council and represent my program in the General Student Council.</p>
                                        <p>At Artevelde University of Applied Sciences in Ghent, I am following the Cloud Application Development track with a focus on Back-End. Here, I learn, among other things, JavaScript, React, PHP, React Native, CMS, REST API, CSS, Tailwind and much more.</p>
                                        
                                        <Button title="View all my skills" url="/expertise" bgColor='blue' />
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {/* Important to me */}
                        <Card>
                            <div className='flex flex-col gap-8' ref={importToMeRef}>
                                <div className='flex flex-col items-center w-full p-8 gap-4'>
                                    <h3 className='font-passionOne uppercase text-8vw text-center'>Important to me</h3>
                                    <p className='text-center sm:w-2/3'>I believe that to be a successful person, it's crucial to maintain balance in my activities, ensuring I have time to learn, grow, and meet new people. Here are some of the things that are important to me.</p>
                                </div>

                                <div className='max-lg:mt-12'>
                                    <div className='w-full overflow-hidden border-b-[1px] border-blue flex relative h-[35vw] lg:h-[20vw]'>
                                        <div className='absolute bottom-0 left-1/2'>
                                            <div className='relative'>
                                                <div className='gsap-circle1 absolute w-[60vw] lg:w-[30vw] h-[60vw] lg:h-[30vw] opacity-20 rounded-full bg-blue top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><p className='bg-white flex flex-col justify-center rounded-full w-5 h-5 text-[10px] text-center relative left-1/2 -translate-x-1/2 top-[2.5%] lg:top-3'>5</p></div>
                                                <div className='gsap-circle2 absolute w-[48vw] lg:w-[24vw] h-[48vw] lg:h-[24vw] opacity-40 rounded-full bg-blue top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><p className='bg-white flex flex-col justify-center rounded-full w-5 h-5 text-[10px] text-center relative left-1/2 -translate-x-1/2 top-[3.5%] lg:top-3'>4</p></div>
                                                <div className='gsap-circle3 absolute w-[36vw] lg:w-[18vw] h-[36vw] lg:h-[18vw] opacity-60 rounded-full bg-blue top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><p className='bg-white flex flex-col justify-center rounded-full w-5 h-5 text-[10px] text-center relative left-1/2 -translate-x-1/2 top-[4%] lg:top-3'>3</p></div>
                                                <div className='gsap-circle4 absolute w-[24vw] lg:w-[12vw] h-[24vw] lg:h-[12vw] opacity-80 rounded-full bg-blue top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><p className='bg-white flex flex-col justify-center rounded-full w-5 h-5 text-[10px] text-center relative left-1/2 -translate-x-1/2 top-[5%] lg:top-3'>2</p></div>
                                                <div className='gsap-circle5 absolute w-[12vw] lg:w-[6vw]  h-[12vw] lg:h-[6vw] rounded-full bg-blue top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><p className='bg-white flex flex-col justify-center rounded-full w-5 h-5 text-[10px] text-center relative left-1/2 -translate-x-1/2 top-[6%] lg:top-3'>1</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex max-md:flex-col gap-6'>
                                    {importantToMe.map((item, index) => (
                                        <div key={index} className='flex flex-col gap-1 md:w-1/5'>
                                            <h4 className=''>{index + 1}. <span className='font-medium'>{item.title}</span></h4>
                                            <p style={{ fontSize: "11px", lineHeight: '16px' }}>{item.caption}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Card>

                    </div>

                    { /* Fun things */}
                    <div className='w-full relative'>
                        <ul className='flex max-md:gap-y-2 gap-x-2 flex-wrap md:hover:text-veryLightBlue transition-colors duration-300 w-full md:w-2/3'>
                            {funThings.map((item, index) => (
                                <li key={`fun-${index}`} className={`flex gap-2 text-8vw md:text-6vw group`}>
                                    <p className={`uppercase font-passionOne text-wrap group-hover:text-blue`}>{item.title}</p>
                                    <p className='font-extralight'>/</p>
                                    <div className='w-[29%] h-full hidden md:group-hover:block absolute right-0 top-0 pointer-events-none'>
                                        <img src={item.img} alt={item.title} className='w-full h-full object-cover rounded-[5px]' />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <WorkTogether />

                </BgSection>
            </div>

        </>
    )
}
