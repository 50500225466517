import card from '../../assets/images/projects/chapter-one-backoffice/card.png';
import banner from '../../assets/images/projects/chapter-one-backoffice/banner.png';
import showcaseWide1 from '../../assets/images/projects/chapter-one-backoffice/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/chapter-one-backoffice/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/chapter-one-backoffice/showcase-wide-3.png';
import devider from '../../assets/images/projects/chapter-one-backoffice/devider.png';
import mobile1 from '../../assets/images/projects/chapter-one-backoffice/mobile-1.png';
import mobile2 from '../../assets/images/projects/chapter-one-backoffice/mobile-2.png';
import mobile3 from '../../assets/images/projects/chapter-one-backoffice/mobile-3.png';

export default {
    title: "Chapter One Backoffice",
    slug: 'chapter-one-backoffice',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/chapter-one-backoffice",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: null,
    },
    description: [
        `Chapter One Backoffice is a PHP-based web application developed for a fictional book store named Chapter One.`,
        `The focus of the project was to create a backoffice system using PHP and mySQL that allows the store to manage products, orders, and customers.`,
        `Styled with Tailwind CSS for a modern look, and utilizing partials for a more organized codebase.`
    ],
    developmentNotes: [
        { title: "PHP", text: "Developed the backoffice system using PHP, enabling dynamic content management and interaction with the mySQL database." },
        { title: "MySQL", text: "Utilized mySQL to store and manage data, allowing for efficient and organized data storage and retrieval." },
        { title: "API", text: "Implemented an API to interact with the database, enabling the backoffice system to retrieve and update data as needed." },
        { title: "DDEV", text: "Used DDEV for local development, providing a Docker-based environment for PHP and mySQL." },
    ],
    mostProudOf: [
        `I'm especially proud of this being my first PHP project, it was a great learning experience to work with PHP and mySQL to create a functional backoffice system.`,
        `I've learned a lot about how to structure and organize PHP code, this project is build with a MVC structure to keep the codebase organized and maintainable.`,
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}