import card from '../../assets/images/projects/rail-link/card.png';
import banner from '../../assets/images/projects/rail-link/banner.png';
import showcaseWide1 from '../../assets/images/projects/rail-link/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/rail-link/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/rail-link/showcase-wide-3.png';
import devider from '../../assets/images/projects/rail-link/devider.png';
import mobile1 from '../../assets/images/projects/rail-link/mobile-1.png';
import mobile2 from '../../assets/images/projects/rail-link/mobile-2.png';
import mobile3 from '../../assets/images/projects/rail-link/mobile-3.png';

export default {
    title: "RailLink",
    slug: 'rail-link',
    platform: 'mobile', // 'web' or 'mobile'
    githubUrl: null,
    mobile: {
        appStoreUrl: "https://apps.apple.com/be/app/raillink/id6505071801",
    },
    web: {
        url: null,
    },
    description: [
        `Introducing RailLink — The Ultimate Travel App for Train Travelers in Belgium.`,
        `Discover all Belgian train stations and get real-time information on train departures and arrivals. Stay informed about any delays, so you're always well-prepared.`,
        `Start from the perspective of the station: RailLink is perfect for travelers who regularly take the same route and only want to know the train times.`,
        `With a user-friendly interface and support for Dutch, English, French, and German, RailLink makes planning your journey simple and efficient.`,
        `Add your favorite stations for quick access and receive relevant notifications, ensuring you never miss a thing.`,
        `RailLink is the perfect companion for your train travels in Belgium. Download it now and start planning your next journey!`,
        `Travel smart, travel with RailLink!`
    ],
    developmentNotes: [
        { title: "SwiftUI", text: "Utilized for creating a responsive and visually appealing user interface." },
        { title: "AppStorage", text: "Used to store the users favorite stations and settings." },
        { title: "WidgetKit", text: "Used for displaying train times on the home screen." },
        { title: "Localization", text: "Support for Dutch, English, French, and German." },
        { title: "MapKit", text: "Used for displaying the location of train stations." },
        { title: "CoreLocation", text: "Used for determining the user's location." },
        { title: 'XCConfig', text: 'Used for managing environment variables.' },
        { title: 'Availability', text: 'Available in Belgium, The Netherlands, France, Germany, and Luxembourg.' },
    ],
    mostProudOf: [
        `I'm proud of RailLink because it's a project that I've been wanting to work on for a long time. I'm a frequent train traveler myself, and I wanted to create an app that would make train travel in Belgium more convenient.`,
        `It was the first time that I've made an app that uses APIs to get real-time data. I'm proud of the way I've implemented this feature, and I'm happy with the way it turned out.`,
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}