import card from '../../assets/images/projects/cook-and-share/card.png';
import banner from '../../assets/images/projects/cook-and-share/banner.png';
import showcaseWide1 from '../../assets/images/projects/cook-and-share/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/cook-and-share/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/cook-and-share/showcase-wide-3.png';
import devider from '../../assets/images/projects/cook-and-share/devider.png';
import mobile1 from '../../assets/images/projects/cook-and-share/mobile-1.png';
import mobile2 from '../../assets/images/projects/cook-and-share/mobile-2.png';
import mobile3 from '../../assets/images/projects/cook-and-share/mobile-3.png';

export default {
    title: "Cook and Share",
    slug: 'cook-and-share',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/cook-and-share",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: null,
    },
    description: [
        `Cook and Share is a web application that allows users to share their favorite recipes with others. Users can create an account, add recipes, and view recipes from other users. The application also includes a backoffice system made using Strapi, where administrators can manage users, recipes, and categories.`,
        `With Cook and Share, you can easily share your favorite recipes with others and discover new recipes to try.`,
        `The application is built using Next.js, GraphQL, Strapi, and Tailwind CSS build in TypeScript. The front-end is built with Next.js and Tailwind CSS, and the backoffice system is built with Strapi. The front-end communicates with the backoffice system using GraphQL queries and mutations.`,
    ],
    developmentNotes: [
        { title: "Next.js", text: "Built the front-end using Next.js, a React framework that enables server-side rendering and static site generation." }, 
        { title: "Next Auth", text: "Implemented authentication using Next Auth, a complete authentication solution for Next.js applications." },
        { title: "GraphQL", text: "Used GraphQL to query and mutate data from the Strapi backoffice system." },
        { title: "Strapi", text: "Built the backoffice system using Strapi, an open-source headless CMS that provides a flexible and extensible content management system." },
        { title: "Tailwind CSS", text: "Styled the application using Tailwind CSS, a utility-first CSS framework that enables rapid development and customization." },
        { title: "Testing", text: "Tested the application using Jest for functional testing and visual components testing." },
    ],
    mostProudOf: [
        `In this project, I am most proud of the integration between the front-end and the backoffice system. I used <span style="font-weight: bold;">GraphQL</span> to query and mutate data from the Strapi backoffice system. I'm proud of how I structured the GraphQL folder and how I handled the data in the front-end.`,
        `I'm also proud of the design, <span style="font-weight: bold;">customization selection components</span> (select one out of many, select multiple), and the responsive design of the application. I used Tailwind CSS to style the application, and I'm proud of the result.`,
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}